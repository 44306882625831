import React, { useEffect, useState } from 'react';
import ICAL from 'ical.js';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import fr from "date-fns/locale/fr";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';
import { FaTimes, FaSun, FaMoon } from 'react-icons/fa';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Button, ButtonGroup, IconButton, Box } from '@mui/material';
import './App.css';
import { FaUserClock, FaWaze, FaPersonSwimming } from "react-icons/fa6";
import { MdOutlineDescription, MdOutlineStart, MdOutlineLocationOn } from "react-icons/md";
import { BsAlignEnd } from "react-icons/bs";
import { SiGooglemaps, SiApple } from "react-icons/si";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { TbInfoSquareRounded } from "react-icons/tb";
import 'moment/locale/fr';

// Configurer les icônes Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Configurer les locales pour le calendrier
const locales = {
  'fr': fr, // Ajout de la locale française
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

Modal.setAppElement('#root');

const defaultCenter = {
  lat: 48.8566, // Latitude par défaut (ex: Paris)
  lng: 2.3522, // Longitude par défaut (ex: Paris)
};

function App() {
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventLocation, setEventLocation] = useState(defaultCenter);
  const [activeView, setActiveView] = useState('calendar');
  const [theme, setTheme] = useState('light'); // État pour le thème

  // Détecter l'heure actuelle et activer le mode nuit par défaut
  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour >= 20 || currentHour < 6) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }, []); // Cette logique ne dépend pas de `theme`

  // Fonction de basculement du thème
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light')); // Toggle entre les thèmes
  };

  useEffect(() => {
    document.body.className = theme === 'dark' ? 'dark-mode' : ''; // Applique la classe de thème
  }, [theme]); // Applique à chaque changement de thème

  const fetchICS = async () => {
    try {
      const response = await fetch('https://cal.teamsud.net/cors-proxy.php?url=https://msinfo.synchroteam.com/app/Calendar/Feed/user/cb67fccb-9428-43e5-90e4-7c2190d5499c.ics');
      const text = await response.text();
      const jcalData = ICAL.parse(text);
      const comp = new ICAL.Component(jcalData);
      const vevents = comp.getAllSubcomponents('vevent');
  
      const parsedEvents = vevents.map((vevent) => {
        const event = new ICAL.Event(vevent);
        return {
          title: event.summary,
          start: new Date(event.startDate.toString()),
          end: new Date(event.endDate.toString()),
          location: event.location,
          description: event.description || 'Aucune description disponible',
          color: 'default',  // Ajout de la couleur bleue pour les événements du second calendrier
        };
      });
  
      return parsedEvents; // Retourner les événements ici
    } catch (error) {
      console.error('Error fetching or parsing ICS file:', error);
      return []; // Retourner un tableau vide en cas d'erreur
    }
  };

  const fetchSecondICS = async () => {
    try {
      const response = await fetch('https://cal.teamsud.net/cors-proxy.php?url=https://msinfo.synchroteam.com/app/Calendar/Feed/user/bc5fd2d1-512e-4544-a39f-11ac8ea2893f.ics');
      const text = await response.text();
      const jcalData = ICAL.parse(text);
      const comp = new ICAL.Component(jcalData);
      const vevents = comp.getAllSubcomponents('vevent');

      const parsedEvents = vevents.map((vevent) => {
        const event = new ICAL.Event(vevent);
        return {
          title: event.summary,
          start: new Date(event.startDate.toString()),
          end: new Date(event.endDate.toString()),
          location: event.location,
          description: event.description || 'Aucune description disponible',
          color: 'blue',  // Ajout de la couleur bleue pour les événements du second calendrier
        };
      });

      return parsedEvents;
    } catch (error) {
      console.error('Error fetching or parsing the second ICS file:', error);
      return [];
    }
  };

  const [mikaelEvents, setMikaelEvents] = useState([]);
  const [patrickEvents, setPatrickEvents] = useState([]);
  

  useEffect(() => {
    const fetchAllEvents = async () => {
      const firstCalendarEvents = await fetchICS();
      const secondCalendarEvents = await fetchSecondICS();

      setMikaelEvents(firstCalendarEvents);
      setPatrickEvents(secondCalendarEvents);

      const allEvents = [...firstCalendarEvents, ...secondCalendarEvents];
      setEvents(allEvents);
    };

    const intervalId = setInterval(() => {
      fetchAllEvents();
    }, 60000);

    fetchAllEvents();

    return () => clearInterval(intervalId);
  }, []);
  

  const openModal = async (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  
    if (event.location) {
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(event.location)}`);
        const data = await response.json();
  
        if (data.length > 0) {
          const lat = parseFloat(data[0].lat);
          const lon = parseFloat(data[0].lon);
          setEventLocation({ lat, lng: lon });
        } else {
          setEventLocation(defaultCenter); // Localisation par défaut si aucune donnée trouvée
        }
      } catch (error) {
        console.error("Error fetching location:", error);
        setEventLocation(defaultCenter); // Retour au centre par défaut en cas d'erreur
      }
    } else {
      setEventLocation(defaultCenter); // Cas où il n'y a pas de localisation
    }
  };
  

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
    setEventLocation(defaultCenter);
  };

  const getNavigationUrl = (location, type) => {
    const encodedLocation = encodeURIComponent(location);
    switch (type) {
      case 'waze':
        return `https://waze.com/ul?q=${encodedLocation}`;
      case 'googleMaps':
        return `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;
      case 'appleMaps':
        return `http://maps.apple.com/?q=${encodedLocation}`;
      default:
        return '#';
    }
  };

  const CenterMap = ({ position }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(position);
    }, [position, map]);  // La carte se réajuste à la nouvelle position
    return null;
  };

  const handleViewChange = (view) => {
    setActiveView(view);
  };

  return (
    <div style={{ height: '100vh', padding: '20px' }}>
      <h2 style={{ textAlign: 'center' }}>Calendrier Professionnel</h2>

      {/* Légende des calendriers */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#2ecc71', // Vert pour Mikael
              marginRight: '8px',
              borderRadius: '4px',
            }}
          ></div>
          <span>Mikael</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#3498db', // Bleu pour Patrick
              marginRight: '8px',
              borderRadius: '4px',
            }}
          ></div>
          <span>Patrick</span>
        </div>
      </div>

      {/* Bouton pour basculer entre les modes jour/nuit */}
      <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ marginBottom: '20px' }}>
        <IconButton onClick={toggleTheme} style={{ color: theme === 'light' ? '#f39c12' : '#f1c40f' }}>
          {theme === 'light' ? <FaMoon size={24} /> : <FaSun size={24} />}
        </IconButton>
        <Button onClick={() => handleViewChange('calendar')}>Calendrier</Button>
        <Button onClick={() => handleViewChange('agenda')}>Agenda</Button>
      </ButtonGroup>


      {activeView === 'calendar' ? (
        <Calendar
          localizer={localizer}
          culture="fr"
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700 }}
          defaultView="month"
          views={['month', 'week', 'day']}
          selectable
          onSelectEvent={openModal}
          eventPropGetter={(event) => {
            const backgroundColor = event.color === 'blue' ? '#3498db' : '#2ecc71'; // Bleu pour Patrick, vert pour Mikael
            return { style: { backgroundColor } };
          }}
          messages={{
            today: "Aujourd'hui",
            previous: 'Précédent',
            next: 'Suivant',
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour',
            agenda: 'Agenda',
          }}
          min={new Date(2024, 0, 1, 6, 0, 0)} // 6h
          max={new Date(2024, 0, 1, 19, 0, 0)} // 19h
        />
      ) : (
        <div className={theme === 'light' ? 'agenda-light' : 'agenda-dark'}>
          <h3>Liste des Rendez-vous</h3>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Colonne des événements de Mikael */}
            <div style={{ flex: 1, marginRight: '10px' }}>
              <h4 style={{ color: '#2ecc71' }}>Agenda Mikael</h4>
              {mikaelEvents.length > 0 ? (
                <ol>
                  {mikaelEvents
                    .filter(event => event.start >= new Date().setHours(0, 0, 0, 0)) // Filtrer uniquement pour l'agenda
                    .sort((a, b) => a.start - b.start)
                    .map((event, index) => (
                      <li
                        key={index}
                        onClick={() => openModal(event)}
                        style={{
                          cursor: 'pointer',
                          padding: '10px',
                          marginBottom: '15px',
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        <strong><FaPersonSwimming /> {event.title}</strong>
                        <br /><br />
                        <em className='description'><TbInfoSquareRounded /> {event.description}</em>
                        <br /><br />
                        <FaUserClock /> {event.start.toLocaleString('fr-FR')} <IoArrowForwardCircleOutline /> {event.end.toLocaleString('fr-FR')}
                      </li>
                    ))}
                </ol>
              ) : (
                <p>Aucun rendez-vous pour Mikael.</p>
              )}
            </div>

            {/* Colonne des événements de Patrick */}
            <div style={{ flex: 1 }}>
              <h4 style={{ color: '#3498db' }}>Agenda Patrick</h4>
              {patrickEvents.length > 0 ? (
                <ol>
                  {patrickEvents
                    .filter(event => event.start >= new Date().setHours(0, 0, 0, 0)) // Filtrer uniquement pour l'agenda
                    .sort((a, b) => a.start - b.start)
                    .map((event, index) => (
                      <li
                        key={index}
                        onClick={() => openModal(event)}
                        style={{
                          cursor: 'pointer',
                          padding: '10px',
                          marginBottom: '15px',
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        <strong><FaPersonSwimming /> {event.title}</strong>
                        <br /><br />
                        <em className='description'><TbInfoSquareRounded /> {event.description}</em>
                        <br /><br />
                        <FaUserClock /> {event.start.toLocaleString('fr-FR')} <IoArrowForwardCircleOutline /> {event.end.toLocaleString('fr-FR')}
                      </li>
                    ))}
                </ol>
              ) : (
                <p>Aucun rendez-vous pour Patrick.</p>
              )}
            </div>
          </div>
        </div>
      )}


      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={`modal-content ${theme === 'light' ? 'modal-light' : 'modal-dark'}`}
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={true}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3>Détails du Rendez-vous</h3>
          <FaTimes style={{ cursor: 'pointer', color: 'red' }} onClick={closeModal} />
        </div>
        {selectedEvent && (
          <div style={{ marginTop: '20px' }}>
            <p><strong><FaPersonSwimming /> {selectedEvent.title}</strong></p>
            {/* Afficher la description ici */}
            {selectedEvent.description && (
              <p><strong><MdOutlineDescription /></strong> {selectedEvent.description}</p>
            )}
            <p><strong><MdOutlineStart /></strong> {selectedEvent.start.toLocaleString('fr-FR')}</p>
            <p><strong><BsAlignEnd /></strong> {selectedEvent.end.toLocaleString('fr-FR')}</p>
            {selectedEvent.location && (
              <p>
                <strong><MdOutlineLocationOn /></strong> {selectedEvent.location}
              </p>
            )}
            {selectedEvent.location && (
              
              <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-evenly' }}>
                <a href={getNavigationUrl(selectedEvent.location, 'waze')} target="_blank" rel="noopener noreferrer">
                  <Button variant="contained" style={{ backgroundColor: '#00B8D9', color: 'white' }}>
                    <FaWaze style={{ marginRight: '10px',fontSize:'2rem' }} />
                    Waze
                  </Button>
                </a>
                <a href={getNavigationUrl(selectedEvent.location, 'googleMaps')} target="_blank" rel="noopener noreferrer">
                  <Button variant="contained" style={{ backgroundColor: '#4285F4', color: 'white' }}>
                    <SiGooglemaps style={{ marginRight: '10px', fontSize:'2rem' }} />
                    Maps
                  </Button>
                </a>
                <a href={getNavigationUrl(selectedEvent.location, 'appleMaps')} target="_blank" rel="noopener noreferrer">
                  <Button variant="contained" style={{ backgroundColor: '#F5F5F7', color: '#000' }}>
                    <SiApple style={{ marginRight: '10px', fontSize:'2rem' }} />
                    Plans
                  </Button>
                </a>
              </div>

            )}
            <MapContainer
              center={eventLocation}
              zoom={13}
              style={{ height: '300px', width: '100%', marginTop: '20px' }}
              scrollWheelZoom={true}  // Pour permettre le zoom avec la molette de la souris
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
              />
              <Marker position={eventLocation}>
                <Popup>{selectedEvent.location}</Popup>
              </Marker>
              <CenterMap position={eventLocation} />
            </MapContainer>
          </div>
        )}
      </Modal>
          {/* Footer */}
      <Box className="footer">Made with ♥️ by Mika</Box>
    </div>
  );
}

export default App;
